<template>
  <el-container></el-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>>

<style lang="scss" scoped>
</style>